import axios from "axios"
import { DateTime } from "luxon"
import { Logger } from "packs/common"
import * as qs from "qs"
import isMobile from "ismobilejs"
import RoomSetting from "packs/models/RoomSetting"
import Room from "packs/models/Room"

//  便利系を記載.
export default class Util {
    static readonly prefixUrl = "/api/front"

    // 0 ~ 30
    static readonly selectDays: number[] = Array(31)
        .fill(0)
        .map((_, i) => i)

    static readonly selectDays100: number[] = Array(101)
        .fill(0)
        .map((_, i) => i)
    static readonly selectDaysStartOne: number[] = Array(31)
        .fill(0)
        .map((_, i) => i + 1)
    // 0 ~ 120 (15min刻み)
    static readonly selectBufferMinutes: number[] = Array(9)
        .fill(0)
        .map((_, i) => i * 15)
    static readonly selectHours: number[] = Array(24)
        .fill(0)
        .map((_, i) => i)
    static weekDics = [
        { windex: 0, name: `日`, selected: false },
        { windex: 1, name: `月`, selected: false },
        { windex: 2, name: `火`, selected: false },
        { windex: 3, name: `水`, selected: false },
        { windex: 4, name: `木`, selected: false },
        { windex: 5, name: `金`, selected: false },
        { windex: 6, name: `土`, selected: false },
    ]
    static readonly thisMonthArray: number[] = Array(DateTime.local().endOf("month").day)
        .fill(0)
        .map((_, i) => i + 1)

    static generateMonthArray(plus: number): number[] {
        return Array(DateTime.local().plus({ month: plus }).endOf("month").day)
            .fill(0)
            .map((_, i) => i + 1)
    }

    static readonly alphabets = [...Array(26)].map((a, b) => (10 + b).toString(36).toUpperCase())

    static checkLoggedInStatus(): string {
        return [`/link#/`, `/link/#/`].some(h => location.href.includes(h)) ? `user` : `guest`
    }

    static isGuest(): boolean {
        if (location.href.includes(`link#/room_settings`)) return true
        return this.checkLoggedInStatus() == `guest` ? true : false
    }

    static isPreview(): boolean {
        if (location.href.includes(`link#/room_settings`)) return true
        return location.href.includes(`schedule_preview`) ? true : false
    }

    static isRoomSettingsPreview() {
        if (location.href.includes(`link#/room_settings`)) return true
        return false
    }

    static isPublic(): boolean {
        if (location.href.includes(`/p/`)) return true
        return false
    }

    static isEmbeds(): boolean {
        if (location.href.includes(`/p/embeds/`)) return true
        if (location.href.includes(`/p/instant_button/`)) return true
        return false
    }

    static isPubSummary(): boolean {
        if (location.href.includes(`/pub_summary`)) return true
        return false
    }

    static isInstantRoom(room: Room): boolean {
        if (!room) return false
        let rs = room.room_setting as RoomSetting
        if (!rs) return false

        if (rs.is_instant_room) return true
        return false
    }

    static isEmbedsInstantButton(): boolean {
        if (location.href.includes(`/p/instant_button/`)) return true
        return false
    }

    static isBoard(): boolean {
        if (location.href.includes(`/p/board/`)) return true
        return false
    }

    static gmapUrl(name, size): string {
        return `https://maps.googleapis.com/maps/api/staticmap?markers=${encodeURIComponent(
            name
        )}&&size=${size}&sensor=false&zoom=15&key=AIzaSyDMG5IzSsuqljchSCDEzW8fZ_wLAFapg6U`
    }

    static gmapLinkUrl(address: string): string {
        return `http://maps.google.com/?q=${encodeURIComponent(address)}`
    }

    /**
     *  お問い合わせを送信.
     *  CATEGORY_BUG = 11
        CATEGORY_OPI = 12
        CATEGORY_INQ = 13
        CATEGORY_ETC = 14
        CATEGORY_REQUEST_DOCUMENT = 15
        CATEGORY_REQUEST_PRO_PLAN = 16
     * @param content [string]
     * @param rating [number]
     * @param category [number]
     */
    static sendInquiry(content, rating = -1, category = 13): Promise<any> {
        return axios
            .post(`${Util.prefixUrl}/inquiries`, {
                content: content,
                rating: rating,
                category: category,
            })
            .then(res => {
                return res
            })
    }

    /**
     * {parent_room_id: `hoge`}の通信用パラメータを作成します。
     * @param parentId [String]
     */
    // static getParentIdStringParams(parentId?: string): string {
    //     let params = {}
    //     Logger(`parent id get rooms: ${parentId}`)
    //     if (parentId && parentId.length > 0) {
    //         params = { parent_room_id: parentId }
    //     }

    //     // Getリクエストのパラムをオブジェクト化するオプション
    //     const paramsSerializer = qs.stringify(params)
    //     return paramsSerializer
    // }

    /**
     * パラメータをURLにつけても化けないようにシリアライズ化します。
     * @param params
     */
    static serialize(params: any) {
        return qs.stringify(params)
    }

    /**
     * objの中身を見たいときに利用します。[object object]のJSONをstringで返します。
     * @param obj
     */
    static output(obj: any): string {
        return `${JSON.stringify(obj)}`
    }

    static getTimeHourMinFormatFromSeconds(sec: number) {
        return DateTime.fromSeconds(sec)
    }

    static isSP() {
        return isMobile(window.navigator).phone
    }

    static getTimeDateFormatFromSeconds(sec: number, format: string = "yyyy年MM月dd日") {
        let _f = format ? format : "yyyy年MM月dd日"
        return DateTime.fromSeconds(sec).toFormat(_f)
    }

    /**
     * オンラインかどうかを判定し、オンラインの場合はツール名を返します.
     * @param address
     */
    static isOnline(address: string) {
        if (Util.isBlank(address)) return null

        // 部分一致でも確定
        const onlines = Util.getOnlines()
        let online = onlines.find(e => address.toLocaleLowerCase().includes(e))
        if (!online) {
            // 完全一致で確定
            const onlinesExactMatch = Util.getOnlinesExactMatch()
            online = onlinesExactMatch.find(e => address.toLocaleLowerCase() == e)
        }
        return online || null
    }

    /**
     * Googleから取得してくるか、電話会議の場合画像を はめます。
     * @param address [string]
     * @param size [string] 320x320
     * @param useLocationIcon [boolean] グーグルマップを叩かずにアイコン画像を利用するか.
     */
    static getURLFromAddress(address, size: string, useLocationIcon = false): string {
        Logger(`Util.getURLFromAddress: ${address}, useLocationIcon: ${useLocationIcon}`)
        // const onlines = Util.getOnlines()

        if (this.isBlank(address)) return ""
        if (address == `自由に場所を入力できます`) return ``

        // Skypeやslackなど
        const online = this.isOnline(address)
        Logger(`Util.getURLFromAddress online:${online}`)
        // const online = onlines.find(e => address.toLocaleLowerCase().includes(e))
        if (online) return this.getOnlineImages(online)

        if (Util.isSP()) {
            // const width = window.outerWidth;
            size = `320x160`
        }

        if (useLocationIcon) return `/assets/call/call_mappin_red`

        // GoogleMapに問い合わせるURLに変換.
        return Util.gmapUrl(address, size)
    }

    /**
     * ディクショナリー または tsのカスタムクラス でtrueを返します.
     * @param val [Any]
     */
    static isDictionary(val: any) {
        if (val !== null && typeof val === "object" && val.constructor === Object) {
            return true
        }
        return false
    }

    static isBlank(str: any): boolean {
        if (!str) return true
        if (typeof str == `string` && (!str || str === `` || str === undefined)) return true
        if (Array.isArray(str) && (!str || str.length == 0)) return true
        if (this.isDictionary(str)) {
            const keys = (Object.keys(str) || []).filter(st => Util.isPresent(st))
            if (keys.length == 0) return true
        }
        return false
    }

    static isPresent(str: any): boolean {
        return !this.isBlank(str)
    }

    static isNumeric(str: string): boolean {
        if (!str) return false
        return /^[0-9]+$/.test(str)
    }

    static isUrl(str: string): boolean {
        if (!str) return false
        const expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi
        const regex = new RegExp(expression)

        return str.match(regex) ? true : false
    }

    static getUrl(url: string) {
        if (!this.isUrl(url)) return null

        let redirect_url = `${url}` + location.search

        if (url.indexOf(`http`) != 0) {
            return `http://${redirect_url}`
        } else {
            return `${redirect_url}`
        }
    }

    static isMac(): boolean {
        return navigator.platform.indexOf("Mac") > -1
    }

    static isWin(): boolean {
        return navigator.platform.indexOf("Win") > -1
    }

    /**
     * オンラインミーティングで利用する配列を返します。
     * 以下に追加する場合、英語では全て小文字に設定してください。( toLocaleLowerCase() で小文字同士を比較するため. )
     *
     */
    static getOnlines(): string[] {
        return [
            "skype",
            "zoom",
            "line",
            "slack",
            "hangout",
            "meet.google",
            "googlemeet",
            "google meet",
            "messenger",
            "bellface",
            "teams",
            "youtube",
            "meet in",
            "meet.in",
            "harutaka",
            "wearby",
            "webex",
            "vimeo",
            "ビメオ",
            "チームス",
            "チームズ",
            "ユーチューブ",
            "ハルタカ",
            "ライン",
            "スカイプ",
            "ズーム",
            "スラック",
            "ハングアウト",
            "メッセンジャー",
            "テレビ電話",
            "ベルフェイス",
            "ウェベックス",
            "電話会議",
        ]
    }

    static getOnlinesExactMatch() {
        return ["remo", "電話", "お電話", "コール", "phone", "telephone", "call"]
    }

    /**
     * 上記のオンラインにあったイメージを返却します。（ない場合は今までどおり、スマホマーク）
     * @param online
     */
    static getOnlineImages(online: string): string {
        if ([`skype`, `スカイプ`].includes(online)) {
            return "/assets/call/call_skype"
        }
        if ([`zoom`, `ズーム`].includes(online)) {
            return "/assets/call/call_zoom"
        }
        if ([`teams`, `チームス`, `チームズ`].includes(online)) {
            return "/assets/call/call_teams"
        }
        if ([`youtube`, `ユーチューブ`].includes(online)) {
            return "/assets/call/call_youtube"
        }
        if ([`line`, `ライン`].includes(online)) {
            return "/assets/call/call_line"
        }
        if ([`messenger`, `メッセンジャー`].includes(online)) {
            return "/assets/call/call_messenger"
        }
        if ([`hangout`, `ハングアウト`, "meet.google", "googlemeet", "google meet"].includes(online)) {
            return "/assets/call/call_google_meet"
        }
        if ([`slack`, `スラック`].includes(online)) {
            return "/assets/call/call_slack"
        }
        if ([`bellface`, `ベルフェイス`].includes(online)) {
            return "/assets/call/call_bellface"
        }
        if ([`webex`, `ウェベックス`].includes(online)) {
            return "/assets/call/call_webex"
        }
        if ([`vimeo`, `ビメオ`].includes(online)) {
            return "/assets/call/call_vimeo"
        }
        if ([`remo`].includes(online)) {
            return "/assets/call/call_remo"
        }
        return "/assets/icons/call_square_grey"
    }

    static validateEmail(email): boolean {
        var re =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(String(email).toLowerCase())
    }

    // 文字列をNumber型に変換した後、自然数のみ評価します。
    static parseNaturalNumber(val: string): number {
        const num = Number(val)
        if (!isNaN(num) && num > 0) {
            return num
        }
        return null
    }

    // 通知タイミング
    static timings(onlyNotify: boolean = false): any {
        let timings = {
            create_room: `調整ページ作成時`,
            notify_message: `チャット通知`,
            shared_calendar: `調整カレンダー共有時`,
            inquire_on_public_room: `申し込み完了時`,
            fixed_appointment: `日程確定時`,
            // the_day_before: `調整ページ有効期限切れ前日`,
            start_appointment: `日程調整開始時`,
            cancel_appointment: `日程確定後キャンセル時`,
            reschedule_appointment: `日程確定後リスケジュール時`,
            one_day_before_deadline: `調整ページ有効期限切れ前日`,
            request_other_possible_dates: `別日程のリクエスト時`,
            // remind_to_share_schedule: `調整カレンダーリクエスト依頼`,
            auto_remind: `自動リマインド`,
            the_day_before: `確定した日程調整の開催日前日`,
            feedback: `イベント後`,
        }
        if (onlyNotify) {
            delete timings.feedback
            delete timings.inquire_on_public_room
        }
        return timings
    }

    // @param roomType [string] parent_room,public_room
    static timingsDicArray(roomType: string = null): any[] {
        let parentTimingNames = [
            `create_room`,
            `shared_calendar`,
            `fixed_appointment`,
            `the_day_before`,
            `feedback`,
            `one_day_before_deadline`,
            `remind_to_share_schedule`,
            `auto_remind`,
        ]
        let publicTimingNames = [
            `inquire_on_public_room`,
            `fixed_appointment`,
            `the_day_before`,
            `feedback`,
            `one_day_before_deadline`,
            `auto_remind`,
        ]
        let parents = []
        let publics = []
        for (let [k, v] of Object.entries(this.timings())) {
            let _v = v as string
            const dic = { type: k, name: _v, is_active: true }

            if (parentTimingNames.includes(k)) {
                //
                dic[`room_type`] = `parent_room`
                parents.push({ ...dic })
            }

            if (publicTimingNames.includes(k)) {
                dic[`room_type`] = `parent_room`
                publics.push({ ...dic })
            }
        }
        return roomType == `parent_room` ? parents : publics
    }

    // 通知タイミング
    static additionalTimings(): any {
        let timings = {
            confirming_open_mail: `メール開封時`,
            confirming_loggin_room: `調整ページ入室時`,
        }
        return timings
    }

    /**
     * 現在時刻を取得します。（秒）
     */
    static getSec(): number {
        return DateTime.local().toSeconds()
    }

    static getRandomColor(): string {
        let color = ((Math.random() * 0xffffff) | 0).toString(16)
        return `000000${color}`.slice(-6)
    }

    static getRandomPublicId(prefix: string = ``, size: number = 6): string {
        return `${prefix}${Math.random()
            .toString(36)
            .toUpperCase()
            .slice(-1 * size)}`
    }

    static toString(str: any) {
        if (!str || str === `` || str === undefined) return ``

        if (typeof str == `string`) return str

        if (Array.isArray(str)) return str.join(`,`)
        if (this.isDictionary(str)) return JSON.stringify(str)
    }

    /**
     * 文字列を16進数に変換する
     * 16進数ひとけたの場合は01 02 03と表記する
     *
     * @method hex
     * @param {String} s 文字列
     * @return {String} result 16進数に変換した文字列
     */
    static hex(s) {
        var result = ""
        for (var i = 0; i < s.length; ++i) {
            var h = ("0" + s.charCodeAt(i).toString(16)).substr(-2)
            result += h
        }
        return result
    }

    static readonly thanksPageDefaultTitle = `お申し込みを受け付けました`

    static readonly thanksPageDefaultContent = `お申し込みいただきありがとうございます。担当が確認いたします。`

    static truncate(str: string, length: number = 20, omission = `...`) {
        if (!str) return

        if (str.length <= length) {
            return str
        }

        return str.substring(0, length) + omission
    }

    /**
     * Railsでいう Set[Array] - Set[Array]で差分のarrayを取得します.
     * @param arr1 [any[]] 長い方 ["a", "b", "c", "d"]
     * @param arr2 [any[]] 短い方 ["a", "c"]
     *
     * @return any[] 期待値: ["b", "d"]
     */
    static arraySabun(arr1: any[], arr2: any[]): any[] {
        Logger(`Util.arraySabun ${Util.output(arr1)}, ${Util.output(arr2)}`)
        arr1 = arr1 || []
        arr2 = arr2 || []
        let result = arr1.filter(
            itemA =>
                // arr2に存在しない要素が返る
                arr2.indexOf(itemA) == -1
        )
        return result || []
    }

    /**
     * 2次元配列に変更します.
     * @param array [any[]] ex.) [1,2,3,4,5,6,7]
     * @param part [number] ex.) 2
     * @returns any[][] ex.)[[1,2],[3,4],[5,6],[7]]
     */
    static splitArray(array: any[], part: number): any[][] {
        if (!array) return null

        let tmp = []
        for (let i = 0; i < array.length; i += part) {
            tmp.push(array.slice(i, i + part))
        }
        return tmp
    }

    /**
     * arrayをkeyに対してソートします.
     * @param array any[]
     * @param key string dic.keyの比較する値
     * @param asc boolean 降順・照準
     * @returns any[]
     */
    static sortBy(array: any[], key: string, asc = true): any[] {
        if (!array) return null
        let arr = [...array]

        arr.sort(function (a, b) {
            if (a[key] < b[key]) return -1
            if (a[key] > b[key]) return 1
            return 0
        })
        return arr
    }

    /**
     * sortByから、文字数が優先でソートします.
     * @param array
     * @param key
     * @param asc
     * @returns
     */
    static sortByWords(array: any[], key: string, asc = true): any[] {
        if (!array) return null
        let arr = [...array]

        arr.sort(function (a, b) {
            if (a[key].length < b[key].length) return -1
            if (a[key].length > b[key].length) return 1
            if (a[key] < b[key]) return -1
            if (a[key] > b[key]) return 1
            return 0
        })
        return arr
    }

    static isSMSEmail(email: string): boolean {
        if (this.isBlank(email)) return false

        return email.endsWith("@sms.waaq.jp")
    }

    /**
     * 開催期間かどうかを判定します.
     * @param room Room
     * @param skipStatus boolean 確定済みの場合のステータスを考慮しないか.
     * @returns [boolean, string] 開催期間外の場合はfalseとメッセージが返ります.
     */
    static isOpenTime(room: Room, skipFixStatus: boolean = false) {
        if (!room) return [true, null]
        let appo = room.current_appointment
        if (!skipFixStatus) {
            if (appo && appo.status == 10) return [true, null]
        }

        let rs = room.room_setting
        if (!rs) return [true, null]
        if (rs.open_period_type == `none`) return [true, null]

        let now = DateTime.local()
        let openTime = rs.open_period_start_at ? DateTime.fromSeconds(rs.open_period_start_at) : null
        let closeTime = rs.open_period_end_at ? DateTime.fromSeconds(rs.open_period_end_at) : null

        if (rs.open_period_type == `start_time_only`) {
            if (now < openTime) return [false, `out_of_start_time`]
        }

        if (rs.open_period_type == `end_time_only`) {
            if (now > closeTime) return [false, `out_of_end_time`]
        }

        if (rs.open_period_type == `start_and_end_time`) {
            if (now < openTime) return [false, `out_of_start_time`]
            if (now > closeTime) return [false, `out_of_end_time`]
        }

        return [true, null]
    }

    static findKeyByValue(obj: any, value: any) {
        return Object.keys(obj).find(key => obj[key] === value)
    }
}
